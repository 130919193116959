import React from 'react';
import ReactDOM from 'react-dom/client'
import App from './App';
import './index.css'
import './i18n.js'


import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

ReactDOM.createRoot(document.getElementById('root')).render(
    <Router>
        <Routes>
        <Route path="/" element={<App />} />
        </Routes>
    </Router>
);


// ReactDOM.render(
//     <App />,
//     document.querySelector("#root"));