import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend';
import dataAR from './Locale/ar/translation.json'
import dataEN from './Locale/en/translation.json'


const resources = {
    en: {
        translation: dataEN,
        Flogo: '../src/assets/G.png'
    },
    ar: {
        translation: dataAR
    },
}


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(HttpApi)
    .init({
        resources,
        fallbackLng: "ar",
        detection: {
            order: [
                'cookie',
                'htmlTag',
                'localStorage',
                'sessionStorage',
                'navigator',
                'htmlTag',
                'path',
                'subdomain'
            ],
            caches: ["cookie"],
        },

        backend: {
            loadPath: './Locale/{{lng}}/translation.json',
        },

    });