import React from 'react'
import './Ourteam.css'
import team1 from '../../assets/team-1.png'
import team2 from '../../assets/team-leader.png'
import team3 from '../../assets/team-3.png'
import team4 from '../../assets/team-4.png'
import team5 from '../../assets/team-5.png'
import team6 from '../../assets/team-6.png'
import team7 from '../../assets/team-7.png'
import team8 from '../../assets/team-8.png'
import team9 from '../../assets/team-9.png'
import teambut from '../../assets/team-but.png'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'




function Ourteam() {
    const { t } = useTranslation();
    return (
        <div> <Helmet>
            <meta charSet="utf-8" />
            <title>شركة المحامي عبدالله أكرم فلاته - للمحاماه والاستشارات القانونية</title>
            <link rel="canonical" href="https://flata.missanvip.com/" />
            <meta name="description" content="تقدم شركة عبدالله أكرم فلاته للمحاماة والاستشارات القانونية خدمات قانونية بتعاملات محلية وعالمية برؤية وقيمة أساسية" />
            <meta name="keywords" content="
                    محامون,
                    مكتب محاماة,
                    استشارات قانونية,
                    محاماة تجارية,
                    محاماة جنائية,
                    خدمات قانونية,
                    محامون في مجال الأعمال,
                    حقوق العمال,
                    قانون الشركات,
                    تسوية المنازعات,
                    قانون العقارات,
                    استشارات قانونية للشركات,
                    محاماة الأسرة,
                    محاماة العقارات,
                    تأسيس الشركات,
                    قانون التأمين,
                    محاماة الطلاق,
                    الإفلاس والإعسار,
                    قانون العمل,
                    تقديم مشورة قانونية,
                    استشارات قانونية مجانية,
                    محامون متخصصون,
                    محامون في مجال الجنح,
                    محامون في مجال الأعمال,
                    محامون في مجال العقارات,
                    محامون في مجال الإرث والوصايا,
                    محامون في مجال الجرائم الإلكترونية,
                    محامون في مجال حقوق الإنسان,
                    محامون في مجال الضرائب,
                    استشارات قانونية عبر الإنترنت,
                    محامون متخصصون في العقود,
                    محامون متخصصون في الجنايات,
                    محامون متخصصون في القانون العمالي,
                    خدمات المحاماة الاستشارية,
                    الدعاوى القضائية والدفوع,
                    الطعن في الأحكام القضائية,
                    التوثيق القانوني,
                    المرافعات في المحاكم,
                    الطعون القانونية,
                    المرافعات القانونية الدولية,
                    شركة المحامي,
                    شركة محاماة,
                    شركة محاماه,
                    عبد الله أكرم,
                    عبد الله فلاته,
                    المحامي فلاتة,
                    أكرم فلاته,
                    المحامي أكرم فلانه,
                    شركه المحامي عبد الله أكرم فلاته,
                    محامون,
                    محاماة,
                    محاميات,
                    محاميه,
                    محامون محاماة,
                    محامين,
                    استشارات قانونية للشركات,
                    استشارات قانونية مجانية,
                    مكتب المحاماة,
                    مكتب محاماة في,
                    محاماة في,
                    شركة المحامي عبد الله أكرم فلاته,
                    شركة المحامي عبد الله فلاته,
                    شركة المحامي فلاته,
                    شركة المحامي أكرم فلاته,
                    محامون,
                    محاماة,
                    محاميات,
                    محاميه,
                    محامون محاماة,
                    محامين,
                    محام,
                    محاما,
                    محامون ومحاماة,
                    محامين ومحاميات,
                    محاماه,
                    محمين,
                    محماة,
                    محاموا,
                    مرحبا بكم بشركة المحامي عبدالله أكرم,
                    شركة المحامي عبدالله أكرم حيث الاختصاص والاعتمادية يلتقيان,
                    العدالة الاحترافية القانونية,
                    العدالة ,الاحترافية ,القانونية,
                    بدر البيتي,
                    المحامي والموثق,
                    عامر الصيعري,
                    بدر الغامدي,
                    فايز الذيابي,
                    ممدوح هندي,
                    المستشار القانوني,
                    المستشار ,القانوني,
                    مستشار,
                    العقود قانونيه,
                    المذكرات قانونية,
                    الاستشارات القانونية,
                    تسجيل وتأسيس الشركات والمؤسسات,
                    القضايا,
                    تركات الورثة,
                    التوثيق والعقارات,
                    تحصيل الديون,
                    Lawyer9k@gmail.com
                    " />
        </Helmet>

            <section id='team'>
                <div className='team-head'>
                    <h1 data-aos="fade-up" >{t("TeamH")}</h1>
                </div>
                <div className="container">
                    <div className="team__container">
                        <article className='team__item' data-aos="fade-right"
                            data-aos-duration="1500">
                            <div className="team__item-img">
                                <img src={team1} alt='img' />
                            </div>
                        </article>
                        <article className='team__item' data-aos="fade-up" data-aos-duration="1500">
                            <div className="team__item-img" >
                                <img src={team2} alt='img' />
                            </div>
                            <div className='team-text'>
                                <h2>{t("name2")}</h2>
                                <h4 className='y-text'>{t("Jtitle2")}</h4>
                            </div>
                        </article>
                        <article className='team__item' data-aos="fade-left"
                            data-aos-duration="1500">
                            <div className="team__item-img">
                                <img src={team3} alt='img' />
                            </div>
                        </article>

                        <article className='team__item' data-aos="fade-up"
                            data-aos-duration="600"
                            data-aos-offset="180"
                        >
                            <div className="team__item-img">
                                <img src={team4} alt='img' />
                            </div>
                            <div className='team-text'>
                                <h2>{t("name4")}</h2>
                                <h4 className='y-text'>{t("Jtitle4")}</h4>
                            </div>
                        </article>
                        <article className='team__item'
                            data-aos="fade-up"
                            data-aos-offset="250"
                            data-aos-duration="800">
                            <div className="team__item-img">
                                <img src={team5} alt='img' />
                            </div>
                            <div className='team-text'>
                                <h2>{t("name5")}</h2>
                                <h4 className='y-text'>{t("Jtitle5")}</h4>
                            </div>
                        </article>
                        <article className='team__item' data-aos="fade-up"
                            data-aos-offset="300"
                            data-aos-duration="1000">
                            <div className="team__item-img">
                                <img src={team6} alt='img' />
                            </div>
                            <div className='team-text'>
                                <h2>{t("name6")}</h2>
                                <h4 className='y-text'>{t("Jtitle6")}</h4>
                            </div>
                        </article>

                        <article className='team__item' data-aos="fade-up"
                            data-aos-duration="600"
                            data-aos-offset="180"
                        >
                            <div className="team__item-img">
                                <img src={team7} alt='img' />
                            </div>
                            <div className='team-text'>
                                <h2>{t("name7")}</h2>
                                <h4 className='y-text'>{t("Jtitle7")}</h4>
                            </div>
                        </article>
                        <article className='team__item'
                            data-aos="fade-up"
                            data-aos-offset="250"
                            data-aos-duration="800">
                            <div className="team__item-img">
                                <img src={team8} alt='img' />
                            </div>
                            <div className='team-text'>
                                <h2>{t("name8")}</h2>
                                <h4 className='y-text'>{t("Jtitle8")}</h4>
                            </div>
                        </article>
                        <article className='team__item' data-aos="fade-up"
                            data-aos-offset="300"
                            data-aos-duration="1000">
                            <div className="team__item-img">
                                <img src={team9} alt='img' />
                            </div>
                            <div className='team-text'>
                                <h2>{t("name9")}</h2>
                                <h4 className='y-text'>{t("Jtitle9")}</h4>
                            </div>
                        </article>

                        <article className='team__item' data-aos="fade-right"
                            data-aos-duration="1500">
                            <div className="team__item-img">
                                <img src={team1} alt='img' />
                            </div>
                        </article>
                        <article className='team__item' data-aos="fade-up" data-aos-duration="1500">
                            <div className="team__item-img" >
                                <img src={teambut} alt='img' />
                            </div>
                            <div className='team-text'>
                                <h2>{t("namebut")}</h2>
                                <h4 className='y-text'>{t("Jtitlebut")}</h4>
                            </div>
                        </article>
                        <article className='team__item' data-aos="fade-left"
                            data-aos-duration="1500">
                            <div className="team__item-img">
                                <img src={team3} alt='img' />
                            </div>
                        </article>



                    </div>
                </div>
            </section>
        </div>
    )
}

export default Ourteam