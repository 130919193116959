import React from 'react'
import './Services.css'
import { useTranslation } from 'react-i18next'
import card1 from "../../assets/card-1.png"
import card2 from "../../assets/card-2.png"
import card3 from "../../assets/card-3.png"
import card4 from "../../assets/card-4.png"
import card5 from "../../assets/card-5.png"
import card6 from "../../assets/card-6.png"
import card7 from "../../assets/card-7.png"
import card8 from "../../assets/card-8.png"
import card9 from "../../assets/card-9.png"
import { Helmet } from 'react-helmet'


function Services() {
    const { t } = useTranslation();
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>شركة المحامي عبدالله أكرم فلاته - للمحاماه والاستشارات القانونية</title>
                <link rel="canonical" href="https://flata.missanvip.com/" />
                <meta name="description" content="تقدم شركة عبدالله أكرم فلاته للمحاماة والاستشارات القانونية خدمات قانونية بتعاملات محلية وعالمية برؤية وقيمة أساسية" />
                <meta name="keywords" content="
            محامون,
            مكتب محاماة,
            استشارات قانونية,
            محاماة تجارية,
            محاماة جنائية,
            خدمات قانونية,
            محامون في مجال الأعمال,
            حقوق العمال,
            قانون الشركات,
            تسوية المنازعات,
            قانون العقارات,
            استشارات قانونية للشركات,
            محاماة الأسرة,
            محاماة العقارات,
            تأسيس الشركات,
            قانون التأمين,
            محاماة الطلاق,
            الإفلاس والإعسار,
            قانون العمل,
            تقديم مشورة قانونية,
            استشارات قانونية مجانية,
            محامون متخصصون,
            محامون في مجال الجنح,
            محامون في مجال الأعمال,
            محامون في مجال العقارات,
            محامون في مجال الإرث والوصايا,
            محامون في مجال الجرائم الإلكترونية,
            محامون في مجال حقوق الإنسان,
            محامون في مجال الضرائب,
            استشارات قانونية عبر الإنترنت,
            محامون متخصصون في العقود,
            محامون متخصصون في الجنايات,
            محامون متخصصون في القانون العمالي,
            خدمات المحاماة الاستشارية,
            الدعاوى القضائية والدفوع,
            الطعن في الأحكام القضائية,
            التوثيق القانوني,
            المرافعات في المحاكم,
            الطعون القانونية,
            المرافعات القانونية الدولية,
            شركة المحامي,
            شركة محاماة,
            شركة محاماه,
            عبد الله أكرم,
            عبد الله فلاته,
            المحامي فلاتة,
            أكرم فلاته,
            المحامي أكرم فلانه,
            شركه المحامي عبد الله أكرم فلاته,
            محامون,
            محاماة,
            محاميات,
            محاميه,
            محامون محاماة,
            محامين,
            استشارات قانونية للشركات,
            استشارات قانونية مجانية,
            مكتب المحاماة,
            مكتب محاماة في,
            محاماة في,
            شركة المحامي عبد الله أكرم فلاته,
            شركة المحامي عبد الله فلاته,
            شركة المحامي فلاته,
            شركة المحامي أكرم فلاته,
            محامون,
            محاماة,
            محاميات,
            محاميه,
            محامون محاماة,
            محامين,
            محام,
            محاما,
            محامون ومحاماة,
            محامين ومحاميات,
            محاماه,
            محمين,
            محماة,
            محاموا,
            مرحبا بكم بشركة المحامي عبدالله أكرم,
            شركة المحامي عبدالله أكرم حيث الاختصاص والاعتمادية يلتقيان,
            العدالة الاحترافية القانونية,
            العدالة ,الاحترافية ,القانونية,
            بدر البيتي,
            المحامي والموثق,
            عامر الصيعري,
            بدر الغامدي,
            فايز الذيابي,
            ممدوح هندي,
            المستشار القانوني,
            المستشار ,القانوني,
            مستشار,
            العقود قانونيه,
            المذكرات قانونية,
            الاستشارات القانونية,
            تسجيل وتأسيس الشركات والمؤسسات,
            القضايا,
            تركات الورثة,
            التوثيق والعقارات,
            تحصيل الديون,
            Lawyer9k@gmail.com
            " />
            </Helmet>

            <section id="serv"
                data-aos="fade-right">
                <h1>{t("servH")}</h1>
                <div className="sec-border">
                    <div className="serv-container">
                        <div className='serv-item-contaiiner'>
                            <article id='card1' className='proto__item'
                                data-aos="fade-right"
                                data-aos-offset="400"
                                data-aos-duration="800">
                                <div className="proto__item-img">
                                    <img src={card1} alt="img" />
                                </div>
                                <h3>{t("card1-t")}</h3>
                                <p>{t("card1-p")}</p>
                            </article>

                            <article id='card2' className='proto__item'
                                data-aos="fade-right"
                                data-aos-offset="300"
                                data-aos-duration="800">
                                <div className="proto__item-img">
                                    <img src={card2} alt="img" />
                                </div>
                                <h3>{t("card2-t")}</h3>
                                <p>{t("card2-p")}</p>
                            </article>

                            <article id='card3' className='proto__item'
                                data-aos="fade-right"
                                data-aos-offset="200"
                                data-aos-duration="800"
                            >
                                <div className="proto__item-img">
                                    <img src={card3} alt="img" />
                                </div>
                                <h3>{t("card3-t")}</h3>
                                <p>{t("card3-p")}</p>
                            </article>

                            <article id='card4' className='proto__item'
                                data-aos="fade-right"
                                data-aos-offset="400"
                                data-aos-duration="800"
                            >
                                <div className="proto__item-img">
                                    <img src={card4} alt="img" />
                                </div>
                                <h3>{t("card4-t")}</h3>
                                <p>{t("card4-p")}</p>
                            </article>

                            <article id='card5' className='proto__item'
                                data-aos="fade-right"
                                data-aos-offset="300"
                                data-aos-duration="800">
                                <div className="proto__item-img">
                                    <img src={card5} alt="img" />
                                </div>
                                <h3>{t("card5-t")}</h3>
                                <p>{t("card5-p")}</p>
                            </article>

                            <article id='card6' className='proto__item'
                                data-aos="fade-right"
                                data-aos-offset="200"
                                data-aos-duration="800"
                            >
                                <div className="proto__item-img">
                                    <img src={card6} alt="img" />
                                </div>
                                <h3>{t("card6-t")}</h3>
                                <p>{t("card6-p")}</p>
                            </article>

                            <article id='card7' className='proto__item'
                                data-aos="fade-right"
                                data-aos-offset="350"
                                data-aos-duration="800">
                                <div className="proto__item-img">
                                    <img src={card7} alt="img" />
                                </div>
                                <h3>{t("card7-t")}</h3>
                                <p>{t("card7-p")}</p>
                            </article>

                            <article id='card8' className='proto__item' data-aos="fade-right"
                                data-aos-offset="300"
                                data-aos-duration="800">
                                <div className="proto__item-img">
                                    <img src={card8} alt="img" />
                                </div>
                                <h3>{t("card8-t")}</h3>
                                <p>{t("card8-p")}</p>
                            </article>

                            <article id='card9' className='proto__item'
                                data-aos="fade-right"
                                data-aos-offset="200"
                                data-aos-duration="800"
                            >
                                <div className="proto__item-img">
                                    <img src={card9} alt="img" />
                                </div>
                                <h3>{t("card9-t")}</h3>
                                <p>{t("card9-p")}</p>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services