import React, { useEffect, useState } from 'react'
import './Headerr.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Sicon from '../Headrr/Icon'
import Carousel from 'react-bootstrap/Carousel';
import slide1 from '../../assets/showcase-1.png'
import slide2 from '../../assets/showcase-2.png'
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';

// import Cookies from 'js-cookie';

function ImgSlider() {
    const { t } = useTranslation();

    const [addBlur, setAddBlur] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setAddBlur(window.innerWidth <= 768); // Assuming mobile width is 768px or less
        };

        // Initial check on mount
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>شركة المحامي عبدالله أكرم فلاته - للمحاماه والاستشارات القانونية</title>
                <link rel="canonical" href="https://flata.missanvip.com/" />
                <meta name="description" content="تقدم شركة عبدالله أكرم فلاته للمحاماة والاستشارات القانونية خدمات قانونية بتعاملات محلية وعالمية برؤية وقيمة أساسية" />
                <meta name="keywords" content="
                    محامون,
                    مكتب محاماة,
                    استشارات قانونية,
                    محاماة تجارية,
                    محاماة جنائية,
                    خدمات قانونية,
                    محامون في مجال الأعمال,
                    حقوق العمال,
                    قانون الشركات,
                    تسوية المنازعات,
                    قانون العقارات,
                    استشارات قانونية للشركات,
                    محاماة الأسرة,
                    محاماة العقارات,
                    تأسيس الشركات,
                    قانون التأمين,
                    محاماة الطلاق,
                    الإفلاس والإعسار,
                    قانون العمل,
                    تقديم مشورة قانونية,
                    استشارات قانونية مجانية,
                    محامون متخصصون,
                    محامون في مجال الجنح,
                    محامون في مجال الأعمال,
                    محامون في مجال العقارات,
                    محامون في مجال الإرث والوصايا,
                    محامون في مجال الجرائم الإلكترونية,
                    محامون في مجال حقوق الإنسان,
                    محامون في مجال الضرائب,
                    استشارات قانونية عبر الإنترنت,
                    محامون متخصصون في العقود,
                    محامون متخصصون في الجنايات,
                    محامون متخصصون في القانون العمالي,
                    خدمات المحاماة الاستشارية,
                    الدعاوى القضائية والدفوع,
                    الطعن في الأحكام القضائية,
                    التوثيق القانوني,
                    المرافعات في المحاكم,
                    الطعون القانونية,
                    المرافعات القانونية الدولية,
                    شركة المحامي,
                    شركة محاماة,
                    شركة محاماه,
                    عبد الله أكرم,
                    عبد الله فلاته,
                    المحامي فلاتة,
                    أكرم فلاته,
                    المحامي أكرم فلانه,
                    شركه المحامي عبد الله أكرم فلاته,
                    محامون,
                    محاماة,
                    محاميات,
                    محاميه,
                    محامون محاماة,
                    محامين,
                    استشارات قانونية للشركات,
                    استشارات قانونية مجانية,
                    مكتب المحاماة,
                    مكتب محاماة في,
                    محاماة في,
                    شركة المحامي عبد الله أكرم فلاته,
                    شركة المحامي عبد الله فلاته,
                    شركة المحامي فلاته,
                    شركة المحامي أكرم فلاته,
                    محامون,
                    محاماة,
                    محاميات,
                    محاميه,
                    محامون محاماة,
                    محامين,
                    محام,
                    محاما,
                    محامون ومحاماة,
                    محامين ومحاميات,
                    محاماه,
                    محمين,
                    محماة,
                    محاموا,
                    مرحبا بكم بشركة المحامي عبدالله أكرم,
                    شركة المحامي عبدالله أكرم حيث الاختصاص والاعتمادية يلتقيان,
                    العدالة الاحترافية القانونية,
                    العدالة ,الاحترافية ,القانونية,
                    بدر البيتي,
                    المحامي والموثق,
                    عامر الصيعري,
                    بدر الغامدي,
                    فايز الذيابي,
                    ممدوح هندي,
                    المستشار القانوني,
                    المستشار ,القانوني,
                    مستشار,
                    العقود قانونيه,
                    المذكرات قانونية,
                    الاستشارات القانونية,
                    تسجيل وتأسيس الشركات والمؤسسات,
                    القضايا,
                    تركات الورثة,
                    التوثيق والعقارات,
                    تحصيل الديون,
                    Lawyer9k@gmail.com
                    " />
            </Helmet>
            <section id='showcase'>
                <div data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="600"
                    data-aos-offset="0">
                    <Carousel data-bs-theme="white H-50">
                        <Carousel.Item>
                            <div className={addBlur ? 'blur' : ''}>
                                <img
                                    className="d-block w-100"
                                    src={slide1}
                                    alt="First slide"
                                />
                            </div>
                            <Carousel.Caption className='slider-content'>
                                <h5> <strong> {t("slideoneB")} </strong></h5>
                                <h5>{t("slideone")}</h5>
                            </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item>
                            <div className={addBlur ? 'blur' : ''}>
                                <img
                                    className="d-block w-100"
                                    src={slide2}
                                    alt="Second slide"
                                />
                            </div>
                            <Carousel.Caption className='slider-content'>
                                <h5> <strong> {t("slidetwoB")} </strong></h5>
                                <h5>{t("slidetwo")}</h5>

                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </div>

                {/* this is sticky icons */}
                <Sicon />
            </section>
        </div>
    );
}


export default ImgSlider