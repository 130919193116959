/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react'
import './Contact.css'
import emailjs from '@emailjs/browser';
import { useTranslation } from "react-i18next";
import { IoIosPhonePortrait } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { GoClock } from "react-icons/go";
import { Helmet } from 'react-helmet';



function Contact() {

    const { t } = useTranslation();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();
        const servicesId = "service_0p5587d";
        const templateId = "template_ylciutx";
        const publikKey = "eTCKxOiQb0IuiLqWX";

        const templateParams = {
            from_name: name,
            from_email: email,
            subject: subject,
            phone_num: phone,
            message: message
        };
        emailjs.send(servicesId, templateId, templateParams, publikKey)
            .then((response) => {
                alert("Email sent successfully \n تم الإرسال بنجاح", response);
                setName('');
                setEmail('');
                setPhone('');
                setSubject('');
                setMessage('');
            })
            .catch((error) => {
                alert("Failed to send message \n تعذر إرسال الرسالة", error);
            });
    };

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>شركة المحامي عبدالله أكرم فلاته - للمحاماه والاستشارات القانونية</title>
                <link rel="canonical" href="https://flata.missanvip.com/" />
                <meta name="description" content="تقدم شركة عبدالله أكرم فلاته للمحاماة والاستشارات القانونية خدمات قانونية بتعاملات محلية وعالمية برؤية وقيمة أساسية" />
                <meta name="keywords" content="
                    محامون,
                    مكتب محاماة,
                    استشارات قانونية,
                    محاماة تجارية,
                    محاماة جنائية,
                    خدمات قانونية,
                    محامون في مجال الأعمال,
                    حقوق العمال,
                    قانون الشركات,
                    تسوية المنازعات,
                    قانون العقارات,
                    استشارات قانونية للشركات,
                    محاماة الأسرة,
                    محاماة العقارات,
                    تأسيس الشركات,
                    قانون التأمين,
                    محاماة الطلاق,
                    الإفلاس والإعسار,
                    قانون العمل,
                    تقديم مشورة قانونية,
                    استشارات قانونية مجانية,
                    محامون متخصصون,
                    محامون في مجال الجنح,
                    محامون في مجال الأعمال,
                    محامون في مجال العقارات,
                    محامون في مجال الإرث والوصايا,
                    محامون في مجال الجرائم الإلكترونية,
                    محامون في مجال حقوق الإنسان,
                    محامون في مجال الضرائب,
                    استشارات قانونية عبر الإنترنت,
                    محامون متخصصون في العقود,
                    محامون متخصصون في الجنايات,
                    محامون متخصصون في القانون العمالي,
                    خدمات المحاماة الاستشارية,
                    الدعاوى القضائية والدفوع,
                    الطعن في الأحكام القضائية,
                    التوثيق القانوني,
                    المرافعات في المحاكم,
                    الطعون القانونية,
                    المرافعات القانونية الدولية,
                    شركة المحامي,
                    شركة محاماة,
                    شركة محاماه,
                    عبد الله أكرم,
                    عبد الله فلاته,
                    المحامي فلاتة,
                    أكرم فلاته,
                    المحامي أكرم فلانه,
                    شركه المحامي عبد الله أكرم فلاته,
                    محامون,
                    محاماة,
                    محاميات,
                    محاميه,
                    محامون محاماة,
                    محامين,
                    استشارات قانونية للشركات,
                    استشارات قانونية مجانية,
                    مكتب المحاماة,
                    مكتب محاماة في,
                    محاماة في,
                    شركة المحامي عبد الله أكرم فلاته,
                    شركة المحامي عبد الله فلاته,
                    شركة المحامي فلاته,
                    شركة المحامي أكرم فلاته,
                    محامون,
                    محاماة,
                    محاميات,
                    محاميه,
                    محامون محاماة,
                    محامين,
                    محام,
                    محاما,
                    محامون ومحاماة,
                    محامين ومحاميات,
                    محاماه,
                    محمين,
                    محماة,
                    محاموا,
                    مرحبا بكم بشركة المحامي عبدالله أكرم,
                    شركة المحامي عبدالله أكرم حيث الاختصاص والاعتمادية يلتقيان,
                    العدالة الاحترافية القانونية,
                    العدالة ,الاحترافية ,القانونية,
                    بدر البيتي,
                    المحامي والموثق,
                    عامر الصيعري,
                    بدر الغامدي,
                    فايز الذيابي,
                    ممدوح هندي,
                    المستشار القانوني,
                    المستشار ,القانوني,
                    مستشار,
                    العقود قانونيه,
                    المذكرات قانونية,
                    الاستشارات القانونية,
                    تسجيل وتأسيس الشركات والمؤسسات,
                    القضايا,
                    تركات الورثة,
                    التوثيق والعقارات,
                    تحصيل الديون,
                    Lawyer9k@gmail.com
                    " />
            </Helmet>

            <div id="contact">
                <br />
                <br />
                <div className='contactH'>
                    <h1 data-aos="zoom-in"
                        data-aos-offset="100"
                        data-aos-duration="500"><span>{t("contactH")}</span></h1>
                </div>
                <div className="contact-container">
                    <div className="conatct-top">
                        <div className="cont-left">
                            <div className="bord phone"
                                data-aos="fade-down-left"
                                data-aos-offset="150"
                                data-aos-duration="300">
                                <i className='phone'><IoIosPhonePortrait /></i>
                                <div className='bord-cont'>
                                    <p className='H'>{t("phoneH")}</p>
                                    <p>+966555612364 - +966568250552</p>
                                </div>

                            </div>
                            <div className="bord mail"
                                data-aos="fade-down-left"
                                data-aos-offset="150"
                                data-aos-duration="500">
                                <i className='mail'><MdEmail /></i>
                                <div className='bord-cont'>
                                    <p>{t("mailH")}</p>
                                    <p>Lawyer9k@gmail.com</p>
                                </div>

                            </div>
                            <div className="bord address"
                                data-aos="fade-down-left"
                                data-aos-offset="150"
                                data-aos-duration="700">
                                <i className='address'><FaMapMarkerAlt /></i>
                                <div className='bord-cont'>
                                    <p>{t("addressH")}</p>
                                    <p>{t("addressP")}</p>
                                </div>

                            </div>
                            <div className="bord time"
                                data-aos="fade-down-left"
                                data-aos-offset="150"
                                data-aos-duration="900">
                                <i className='time'><GoClock /></i>
                                <div className='bord-cont'>
                                    <p>{t("timeH")}</p>
                                    <p>{t("timeP")}</p>
                                </div>
                            </div>
                        </div>

                        <div className="cont-right">
                            <div className="map" data-aos="fade-down"
                                data-aos-offset="100"
                                data-aos-duration="1000">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d3710.1497191174603!2d39.16637840075357!3d21
                            .580078189482855!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDM0JzUzLjciTiAzOcKwMDknNTguNyJF!5e0!3m2!1sen!2seg!4v1713736348097!5m2!1sen!2seg"
                                    allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="bot-container">
                    <div className="contact-but"
                        data-aos="fade-down-left"
                        data-aos-offset="150"
                        data-aos-duration="500">
                        <h2 className='msgH'>{t("msgH")}</h2>
                    </div>

                    <div className="cont-input">
                        <div className="form-container">
                            <div className="input">
                                <form onSubmit={sendEmail} className='emailForm'>
                                    <div className='top-col'>
                                        <div>
                                            <input type="email"
                                                name='email'
                                                placeholder={t("email")}
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                data-aos="fade-left"
                                                data-aos-offset="150"
                                                data-aos-duration="100"
                                                required
                                            />
                                            <input type="text"
                                                name='name'
                                                placeholder={t("ename")}
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                data-aos="fade-right"
                                                data-aos-offset="150"
                                                data-aos-duration="900"
                                                required
                                            />
                                        </div>

                                        <div>
                                            <input type="text"
                                                placeholder={t("subj")}
                                                value={subject}
                                                onChange={(e) => setSubject(e.target.value)}
                                                data-aos="fade-left"
                                                data-aos-offset="50"
                                                data-aos-duration="100"
                                                required
                                            />
                                            <input
                                                name='Phone'
                                                placeholder={t("phone")}
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                data-aos="fade-right"
                                                data-aos-offset="80"
                                                data-aos-duration="900"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className='col-but'
                                        data-aos="fade-right"
                                        data-aos-offset="80"
                                        data-aos-duration="900">
                                        <textarea
                                            placeholder={t("mesg")}
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)} required />
                                        <button type='submit' className='sub-btn'> {t("send")}</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact